import React from 'react';
import FolderIconOutlined from '@mui/icons-material/FolderOpenOutlined';
import { RoundBadge, Text, theme, Tooltip } from '../../common';

import * as S from './styles';

type CommonProps = {
  name: string;
  path: string;
  showFullPath?: boolean;
  isDeleted?: boolean;
};

type Props = CommonProps & {
  isJob: boolean;
  enabled: boolean;
};

export const JobGroupPath: React.FC<Props> = ({
  name,
  path,
  showFullPath,
  isDeleted,
  ...props
}) => {
  const renderIcon = () => {
    if (props.isJob) {
      return props.enabled && !isDeleted ? (
        <RoundBadge
          className="round-badge"
          color={theme.colors.green40}
          size={14}
          $marginRight={8}
        />
      ) : (
        <RoundBadge
          className="round-badge"
          color={theme.colors.grey40}
          size={14}
          $marginRight={8}
        />
      );
    }

    return <FolderIconOutlined />;
  };

  const renderPrefix = () => {
    const pathParts = path.split('/').slice(1, -1);
    if (!showFullPath || pathParts.length === 1) return;

    return (
      <>
        <S.JobNameCol $maxWidth={104} $color={theme.colors.grey50}>
          <FolderIconOutlined />
          <Text $color={theme.colors.grey50}>{pathParts[0]}</Text>
        </S.JobNameCol>
        {pathParts.length > 1 && (
          <S.JobNameCol $color={theme.colors.grey50}>
            <Tooltip title={pathParts.join(' / ')}>
              <Text $color={theme.colors.grey50}>
                /{pathParts.length > 2 && ' ... /'}
              </Text>
            </Tooltip>
          </S.JobNameCol>
        )}
      </>
    );
  };

  const jobName = (
    <S.JobNameCol>
      {renderIcon()}
      <Text>
        {name}
        {isDeleted ? ' (deleted)' : ''}
      </Text>
    </S.JobNameCol>
  );

  return (
    <S.JobNameRow $isDeleted={isDeleted}>
      {renderPrefix()}
      {jobName}
    </S.JobNameRow>
  );
};
