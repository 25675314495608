import { Col, Row } from 'antd';
import styled, { css } from 'styled-components';

export const JobNameRow = styled(Row)<{ $isDeleted?: boolean }>`
  margin: 12px;
  gap: 12px;
  align-items: center;
  flex: 1;
  overflow: auto;

  ${({ $isDeleted, theme }) =>
    $isDeleted &&
    css`
      & .ant-typography,
      & svg {
        color: ${theme.colors.grey30}!important;
      }

      & .round-badge {
        background: ${theme.colors.grey30}!important;
      }
    `}

  flex-wrap: nowrap;

  & .ant-col {
    display: flex;
    align-items: center;
    overflow: auto;

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & svg {
    font-size: 14px;
    margin-right: 6px;
  }
`;

export const JobNameCol = styled(Col)<{ $color?: string; $maxWidth?: number }>`
  ${({ $color }) => $color && `color: ${$color};`}
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}px;`}
`;
